const baseUrlPrivate = '/bilingue';
const baseUrlPublic = '/psbilingue';


const urlsBilingue = {
    dashboard: baseUrlPrivate,
    inscricoes: `${baseUrlPrivate}/inscricoes`,
    indeferir: `${baseUrlPrivate}/indeferir`,
    indeferirId: `${baseUrlPrivate}/indeferir/:id`,
    visualizar: `${baseUrlPrivate}/visualizar/`,
    visualizarId: `${baseUrlPrivate}/visualizar/:id`,
    formulario: `${baseUrlPublic}`,
    formulario2024: `${baseUrlPublic}/formulario`,
    formularioCivico: `${baseUrlPublic}/formulario/civico/`,
    formularioGastronomia: `${baseUrlPublic}/formulario/gastronomia/`,
}

export default urlsBilingue;