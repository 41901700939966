import Breadcrumb from "../../components/breadcrumb";
import Base from "./baseGestao";
import { getDashboard } from "../../services/dashboard";
import { useQuery } from 'react-query';
import Spinner from '../../components/spinner';

const HomeGestao = () => {
  const { data, isLoading } = useQuery('getDashboard', getDashboard, {
    retry: false,
  });

  return (
    <Base>
      <Breadcrumb title={'Informações Gerais'} itens={['Gestão', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="row">
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">school</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Unidades</span>
                        <span className="widget-stats-amount">{data.unidades}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-warning">
                        <i className="material-icons-outlined">schedule</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Turnos</span>
                        <span className="widget-stats-amount">{data.turnos}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-purple">
                        <i className="material-icons-outlined">class</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Turmas</span>
                        <span className="widget-stats-amount">{data.turmas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
             
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-danger">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Alunos</span>
                        <span className="widget-stats-amount">{data.alunos}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-ligth">
                        <i className="material-icons-outlined">people_alt</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Servidores</span>
                        <span className="widget-stats-amount">{data.servidores}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </Base>
  )
};

export default HomeGestao;