import Breadcrumb from "../../../components/breadcrumb";
import BaseChamadaPublica from "./baseChamadaPublica";
import { getDashboard } from "../../../services/processos/chamadaPublica";
import { useQuery } from 'react-query';
import Spinner from '../../../components/spinner';

const HomeChamadaPublica = () => {
  const { data, isLoading } = useQuery('getDashboard', getDashboard, {
    retry: 3
  });

  return (
    <BaseChamadaPublica>
      <Breadcrumb title={'Estatísticas Chamada Pública'} itens={['Chamada Pública', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
          
          <h4>Dados Gerais Inscrições</h4>

          <div className="row">
          <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscritos</span>
                        <span className="widget-stats-amount">{data?.inscricoesTotal}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscritos Por CPF</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscritos Lista Espera</span>
                        <span className="widget-stats-amount">{data?.inscricoesListaEsperaTotal}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscritos Lista Espera (Rede)</span>
                        <span className="widget-stats-amount">{data?.inscricoesListaEsperaTotalRede}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscritos Lista Espera (Ñ Rede)</span>
                        <span className="widget-stats-amount">{data?.inscricoesListaEsperaTotalForaRede}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscritos Convocados</span>
                        <span className="widget-stats-amount">{data?.inscricoesConvocadas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">Total Inscritos Matriculados</span>
                        <span className="widget-stats-amount">{data?.inscricoesMatriculadas}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              

          <h4>Dados Por Escolaridade (Lista de Espera)</h4>

          <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">1º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas1}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">2º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">3º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas3}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">4º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas4}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">5º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas5}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">6º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas6}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">7º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas7}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">8º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas8}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">9º ANO</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas9}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            
              
              </div>

              <div className="row">
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">CRECHE I</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas10}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">CRECHE II</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas11}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">CRECHE III</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas12}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">CRECHE IV</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas13}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              </div>

             

              <div className="row">

              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">PRÉ I</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas24}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-xl-6">
                <div className="card widget widget-stats">
                  <div className="card-body">
                    <div className="widget-stats-container d-flex">
                      <div className="widget-stats-icon widget-stats-icon-primary">
                        <i className="material-icons-outlined">groups</i>
                      </div>
                      <div className="widget-stats-content flex-fill">
                        <span className="widget-stats-title">PRÉ II</span>
                        <span className="widget-stats-amount">{data?.inscricoesValidas26}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              
              </div>
           
            
          </>
        )}
    </BaseChamadaPublica>
  )
};

export default HomeChamadaPublica;