import { useEffect, useState, useMemo } from 'react';
import * as S from './style';
import FormField from '../../../../../../components/formField';
import Table from '../../../../../../components/table';
import { useParams } from 'react-router-dom'
import api from '../../../../../../services';
import { useNavigate } from 'react-router-dom'
import urlsRemanejamento from '../../../private/urlsRemanejamento';

const RemanejamentoVisualizacaoFormulario = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { id } = useParams()

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/processoSeletivo/inscricao/remanejamento/${id}`);
      setData(response.data.data)
    }
    fetchData()
  }, [])

  

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      {
        Header: 'Data e Hora', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.criacao.split('T')[1].split('.')[0]
        )
      },
      { Header: 'Status', accessor: 'status' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <S.TableButton 
            onClick={() =>
              {  window.open(urlsRemanejamento.visualizar + row.original.id, '_blank')}
              // navigate(urls.processosSeletivosVInscricao + row.original.id)
            }
            className='btn btn-sm btn-primary'>
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </S.TableButton>
          </>
        )
      },
    ],
    []
  )

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '24px 48px',
      backgroundImage: 'url("https://pre-matricula.educacaoararuama.rj.gov.br/_form/images/fundo.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      height: '100vh'
    }}>
      <img
        src='https://pre-matricula.educacaoararuama.rj.gov.br/assets/araruama/araruama-logo-principal.png'
        alt="Logo" width="200" height="150"
      />
      <h6 style={{ marginTop: '10px', alignSelf: 'center' }}>PREFEITURA MUNICIPAL DE ARARUAMA</h6>
      <h4 style={{ alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO</h4>
      <S.Container>
        <S.FormHeader>FORMULÁRIO DE CONFIRMAÇÃO DE REMANEJAMENTO 2024</S.FormHeader>

        <S.LineDivider />
        
        <S.Row>
          <FormField label="ID" value={data?.id} fw={25} />
          <FormField label="Data e Hora" value={data?.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + data?.criacao.split('T')[1].split('.')[0]} fw={25} />
          <FormField label="Status" value={data?.status == 1 ? "EM ANÁLISE" : data?.status == 2 ? "CANCELADO" : data?.status == 3 ? "DEFERIDO" : "INDEFERIDO | " + data?.motivo} fw={50} />
        </S.Row>

        <S.LineDivider />
        <S.Row>
          <FormField label="nome" value={data?.nome} fw={33.3} />
          <FormField label="data nascimento" value={data?.dataNascimento.split('T')[0].split('-').reverse().join('/')} fw={33.3} />
          <FormField label="Bairro" value={data?.bairroNome} fw={50} />
          <FormField label="deficiente" value={data?.deficiente ? 'SIM' : 'NÃO'} fw={34} />
        </S.Row>
        

        <S.LineDivider />

        <S.Row>
          <FormField label="nome responsável" value={data?.responsavelNome} fw={25} />
          <FormField label="Data Nascimento Responsável" value={data?.responsavelNascimento.split('T')[0].split('-').reverse().join('/')} fw={25} />
          <FormField label="cpf responsável" value={data?.responsavelCpf} fw={25} />
          <FormField label="Telefone responsável" value={data?.responsavelTelefone} fw={25} />

          
        </S.Row>
       

        <S.LineDivider />

        <S.Row>
          <FormField label="escola de origem" value={data?.unidadeAtualNome} fw={33.3} />
          <FormField label="Opção 1" value={data?.unidadeOpcao1Nome} fw={33.3} />
          <FormField label="Opção 2" value={data?.unidadeOpcao2Nome} fw={34} />
        </S.Row>

        <S.LineDivider />

        <Table
          columns={columns}
          data={data?.inscricoesAntigas ? data?.inscricoesAntigas : []}
        />

        <S.LineDivider />

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <S.PrintButton type="button" onClick={() => {
            // window.print()
            navigate('/inscricao')
          }}>Voltar</S.PrintButton>
          <S.PrintButton type="button" onClick={() => {
            window.print()
            navigate('/forms/rnj')
          }}>Imprimir</S.PrintButton>
           
        </div>


      </S.Container>
    </div>
  )
}

export default RemanejamentoVisualizacaoFormulario
