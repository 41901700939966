import { useMemo, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation, json } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from '../../../components/breadcrumb';
import urlsDocente from '../../docente/urlsDocente';
import urlsGestao from '../../gestao/urlsGestao';
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import { useMutation } from 'react-query';
import { getAvaliacoes, getDisciplina, getVisualizacao } from '../../../services/diario-classe';

import { getEscolaridadesUnidade } from '../../../services/escolaridades';
import { getTurnosUnidadeEscolaridade } from '../../../services/turnos';
import { getTurmasUnidadeEscolaridadeTurno } from '../../../services/turmas';

import { useQuery } from 'react-query'
import './estilo.css'
import BaseGestao from '../../gestao/baseGestao';
import BaseGestaoUnidades from '../../gestao-unidades/baseGestaoUnidades';
import BaseDocente from '../../docente/baseDocente';

const VisualizacaoDiarioPage = (gestao) => {
  const unidade = localStorage.getItem('unidadeID');
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [linha, setLinha] = useState(1);
  const [escolaridade, setEscolaridade] = useState("");
  const [turno, setTurno] = useState("");
  const [turma, setTurma] = useState("");
  const [periodo, setPeriodo] = useState("");
  const [avaliacao, setAvaliacao] = useState("");
  const [ciclo, setCiclo] = useState("");
  const [disciplina, setDisciplina] = useState("");
  const [listaCiclo, setListaCiclo] = useState([]);
  const [cabecalho, setCabecalho] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [pontuacoes, setPontuacoes] = useState({});
  const [exibeResultado, setExibeResultado] = useState(false);
  const [mensagem, setMensagem] = useState('Use o filtro acima para visualizar o boletim');
  const [escolaridades, setEscolaridades] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [turmas, setTurmas] = useState([]);

  const [ciclos, setCiclos] = useState(0);

  let BaseComponent;
  let BasePath;
  let adicionarUrl;

  // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão'
    adicionarUrl = urlsGestao.diarioClasseAdicionar
  } 
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades'
    adicionarUrl = urlsGestaoUnidades.diarioClasseAdicionar
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente'
    adicionarUrl = urlsDocente.notasAdicionar
  }

  useEffect(() => {
    getEscolaridadesUnidade();
  }, [])

  const populaCiclo = (periodo) => {
    var lista = [];
    if (periodo === "1") {
      for (let i = 1; i <= 12; i++)
        lista.push({ id: i, descricao: `${i}º MÊS` });
      setListaCiclo(lista);
    } else if (periodo === "2") {
      for (let i = 1; i <= 6; i++)
        lista.push({ id: i, descricao: `${i}º BIMESTRA` });
      setListaCiclo(lista);
    } else if (periodo === "3") {
      for (let i = 1; i <= 4; i++)
        lista.push({ id: i, descricao: `${i}º TRIMESTR` });
      setListaCiclo(lista);
    } else if (periodo === "4") {
      for (let i = 1; i <= 2; i++)
        lista.push({ id: i, descricao: `${i}º SEMESTRE` });
      setListaCiclo(lista);
    } else if (periodo === "5") {
      for (let i = 1; i <= 1; i++)
        lista.push({ id: i, descricao: `${i}º ANO` });
      setListaCiclo(lista);
    } else {
      setCiclos('');
    }
  }

  const getEscolaridade = async () => {
    var result = await getEscolaridadesUnidade(localStorage.getItem('unidadeID'));
    setEscolaridades(result);
    getTurno();
  }

  const getTurno = async () => {
    var result = await getTurnosUnidadeEscolaridade(unidade, escolaridade);
    setTurnos(result);
    getTurma();
  }


  const getTurma = async () => {
    if (turno != '' && escolaridade != '') {
      var result = await getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno);
      setTurmas(result.data);
    }
  }
  // const { data: escolaridades } = useQuery('getEscolaridades', () => (unidade !== null ? getEscolaridades(unidade) : null),
  //   {
  //     retry: 3,
  //     enabled: unidade !== null
  //   }
  // )

  // const { data: turmas, isLoading: loadingTurmas, refetch: refetchTurmas } = useQuery('getTurmas', () =>
  //   unidade !== '' && escolaridade !== '' && turno !== '' ? getTurmas(unidade, escolaridade, turno) : null,
  //   {
  //     retry: 3,
  //     enabled: unidade !== '' && escolaridade !== '' && turno !== ''
  //   }
  // )

  // const { data: turnos, isLoading: loadingTurnos, refetch: refetchTurnos } = useQuery('getTurnos', () =>
  //   unidade !== '' && escolaridade !== '' ? getTurnos(unidade, escolaridade) : null,
  //   {
  //     retry: 3
  //   }
  // )

  const { data: avaliacoes, isLoading: loadingavaliacoes, refetch: refetchavaliacoes } = useQuery('getAvaliacoes', () =>
    unidade !== '' && escolaridade !== '' && turno !== '' && turma !== '' && periodo !== '' ? getAvaliacoes(unidade, escolaridade, turno, turma, periodo, ciclo) : null,
    {
      retry: 3
    }
  )

  const { data: disciplinas, isLoading: loadingDisciplinas, refetch: refetchDisciplina } = useQuery('getDisciplina', () =>
    getDisciplina(),
    {
      retry: 3
    }
  )

  useMemo(() => {
    (async () => {
      if (state) {
        if (JSON.parse(localStorage.getItem('unidadeID')) != state.diarioClasse.idUnidade && state.diarioClasse.idUnidade != undefined) {
          localStorage.setItem('unidadeID', state.diarioClasse.idUnidade);
          window.location.reload();
        }
        setEscolaridade(state.diarioClasse.idEscolaridade);
        setTurno(state.diarioClasse.idTurno);
        setTurma(state.diarioClasse.idTurma);
        setPeriodo(state.diarioClasse.periodo);
        populaCiclo(state.diarioClasse.periodo.toString());
        // setCiclo(state.diarioClasse.ciclos);
        // await refetchavaliacoes();
      }
    })();
  }, [id, state]);

  const getDados = async () => {
    setCabecalho([]);
    setAlunos([]);
    var response = await getVisualizacao(unidade, escolaridade, turno, turma, periodo, disciplina, ciclo);
    setCabecalho(response.data.listaAcademicoDiarioClasseAvaliacoes);
    setAlunos(response.data.alunosNotas);

    if (response.data.listaAcademicoDiarioClasseAvaliacoes.length > 0) {
      setExibeResultado(true);
    } else {
      setMensagem("Nenhum registro lançado com o filtro selecionado");
      setExibeResultado(false);
    }
  }

  const imprimir = () => {
    var dados = {
      curso: document.getElementById('idEscolaridade').selectedOptions[0].text,
      materia: document.getElementById('disciplina').selectedOptions[0].text,
      cabecalho: cabecalho,
      alunos: alunos
    };
    localStorage.setItem('boletim', JSON.stringify(dados));
    window.open('/impressao-boletim-diario-classe', '_blank');
  }

  return (
    <BaseComponent>
      <Breadcrumb title={'Diário de Classe - Visão Geral'} itens={[BasePath, 'Diário de classe', 'Visão Geral']} />
      <form>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-3'>
                      <label htmlFor="descricao" className="form-label">ESCOLARIDADE</label>
                      {
                        <select className="form-select" disabled={true} id="idEscolaridade" value={escolaridade}
                          onChange={async e => {
                            await setEscolaridade(e.target.value)
                            await setTurno("");
                            await setTurma("");
                            await getTurno();
                            await getTurma();
                          }}>
                          <option value="">Selecione</option>
                          {escolaridades && escolaridades.map(item => (
                            <option key={item.id} value={item.id}>{item.descricao}</option>
                          ))}
                        </select>
                      }
                    </div>

                    <div className='col-3'>
                      <label htmlFor="descricao" className="form-label">TURNO</label>
                      {
                        <select className="form-select" disabled={true} id="idTurno" value={turno}
                          onChange={async e => {
                            await setTurno(e.target.value)
                            await setTurma("");
                          }}>
                          <option value="">Selecione</option>
                          {turnos && turnos.map(item => (
                            <option key={item.id} value={item.id}>{item.descricao}</option>
                          ))}
                        </select>
                      }
                    </div>

                    <div className='col-3'>
                      <label htmlFor="descricao" className="form-label">TURMA</label>
                      {
                        <select className="form-select" disabled={true} id="turmaId" value={turma}
                          onChange={async e => {
                            await setTurma(e.target.value);
                            await refetchavaliacoes();
                          }}>
                          <option value="">Selecione</option>
                          {turmas && turmas.map(item => (
                            <option key={item.id} value={item.id}>{item.descricao}</option>
                          ))}
                        </select>
                      }
                    </div>

                    <div className='col-3'>
                      <label htmlFor="descricao" className="form-label">PERÍODO</label>
                      {
                        <select className="form-select" disabled={true} id="periodo" value={periodo}
                          onChange={async e => {
                            await setPeriodo(e.target.value);
                            await populaCiclo(e.target.value);
                          }}>
                          <option value="0">Selecione</option>
                          <option value="1">MENSAL</option>
                          <option value="2">BIMESTRAL</option>
                          <option value="3">TRIMESTAL</option>
                          <option value="4">SEMESTRAL</option>
                          <option value="5">ANUAL</option>
                        </select>
                      }
                    </div>



                  </div>

                  <div className='row' style={{ marginTop: 30 }}>

                    <div className='col-3'>
                      <label htmlFor="descricao" className="form-label">CICLO</label>
                      {
                        <select className="form-select" id="periodo" value={ciclo}
                          onChange={async e => {
                            await setCiclo(e.target.value);
                            await refetchavaliacoes();
                            // await populaCiclo(e.target.value);
                            // await refetchavaliacoes();
                          }}>
                          <option value="">Selecione</option>
                          {listaCiclo.map(item => (
                            <option key={item.id} value={item.id}>{item.descricao}</option>
                          ))}
                        </select>
                      }
                    </div>

                    <div className='col-3'>
                      <label htmlFor="descricao" className="form-label">DISCIPLINA</label>
                      {
                        <select className="form-select" id="disciplina" value={disciplina}
                          onChange={async e => {
                            await setDisciplina(e.target.value)
                          }}>
                          <option value="">Selecione</option>
                          {disciplinas && disciplinas?.data.map(item => (
                            <option key={item.id} value={item.id}>{item.descricao}</option>
                          ))}
                        </select>
                      }
                    </div>

                    <div className='col-6'>
                      <button type='button' onClick={getDados} className='btn btn-primary' style={{ marginTop: 33 }}>Visualizar</button>
                      {
                        exibeResultado ?
                          <button type='button' onClick={imprimir} className='btn btn-success' style={{ marginTop: 33, float: 'right' }}>Imprimir</button>
                          : <></>
                      }
                    </div>
                  </div>
                </>
                {
                  exibeResultado ?
                    <div className='row' style={{ marginTop: 30 }}>
                      <div className='col-12'>
                        <table className='tbmVisao'>
                          <thead>
                            <tr style={{ paddinfLeft: 50 }}>
                              <th>Nº</th>
                              <th>Matrícula</th>
                              <th>Aluno</th>{
                                cabecalho.map((item) => {
                                  return (<th className='txt-center rotate'>{item.abreviacao}</th>)
                                })
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              alunos.map((aluno) => {
                                // linha = linha + 1
                                return (
                                  <tr>
                                    <td>{aluno.linha}</td>
                                    <td>{aluno.matricula}</td>
                                    <td>{aluno.nomeAluno}</td>
                                    {
                                      cabecalho.map((item) => {
                                        return (
                                          <td className='txt-center normal-font'>
                                            {
                                              aluno.notas.find(x => x.idAvaliacao == item.id) == undefined
                                                ? '0'
                                                : aluno.notas.find(x => x.idAvaliacao == item.id).nota
                                            }
                                          </td>)
                                      })
                                    }
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    : <h5 className='text-center' style={{ marginTop: 40 }} >{mensagem}</h5>
                }
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseComponent >
  )

};

export default VisualizacaoDiarioPage;