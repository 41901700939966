import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  padding: 15px 15px 25px 15px;
  right: -560px;
  top: -2px;
  width: 550px;
  border-radius: 8px;
  background: rgba(255, 255, 254, 1);
  border: 0.5px solid #000;
`;

export const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  cursor: pointer;
`;

export const TabOption = styled.h6`
  border-bottom: ${(props) => props.isActive && '2px solid #FF9500'};
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px;
  margin-top: 8px;
  background: rgba(0, 0, 0, 0.08);
`;

export const FiltersOptions = styled.div`
  margin-left: 35px;
  font-size: 14px;
  padding: 5px;
  color: rgba(0, 0, 0, 0.6);
  background: ${props => props.isFilterActive && 'rgba(0, 0, 0, 0.15)'};
  cursor: pointer;
`;

export const NotificationItensContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

export const NotificationItem = styled.div`
  display: flex;
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
  background: ${props => props.priority || null};
  cursor: pointer;

  &:hover {
    background: ${props => props.priorityHover || null};
  }
`;

export const NotificationItemText = styled.div`
  display: flex;
  position: relative;
  width: 90%;
  > p {
    margin: 0px 0px 0px 20px;
    padding-bottom: 15px;
  }
`;

export const NotificationItemIcon = styled.div`
  position: absolute;
  right: -30px;
`

export const NotificationItemData = styled.div`
  position: absolute;
  right: -30px;
  bottom: -25px;

  > p {
    font-size: 11px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px
`;