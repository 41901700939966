import axios from 'axios';

export class LocationApi {
  async searchByCep(cep, signal) {
    cep = cep.replace(/\D/g, '');
    let tenantId = `${process.env.REACT_APP_TENANT_ID}`
    const url = `https://api.shiftlabs.com.br/endereco/cep`;

    return axios.get(`${url}/${cep}?tenantId=${tenantId}`, {
      signal,
    });
  }
}
