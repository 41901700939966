import urlsFichaAvaliacao from "./urlsFichaAvaliacao";

const linksFichaAvaliacao = (moduloDocenteGrupoGestaoEducacional, moduloDocenteGrupoAcademico, moduloDocenteGrupoDiario) => {
  return (
    {
      gestao_educacional: {
        id: 'gestao_educacional',
        text: 'GESTÃO EDUCACIONAL',
        type: 'title',
        visible: moduloDocenteGrupoGestaoEducacional == 1,
        itens: {
          dashboard: {
            id: 'dashboard',
            text: 'DASHBOARD',
            path: urlsFichaAvaliacao.dashboard,
            icon: 'dashboard',
            visible: moduloDocenteGrupoGestaoEducacional == 1,
          },
          // calendario: {
          //   id: 'calendario',
          //   text: 'CALENDÁRIO',
          //   path: urlsFichaAvaliacao.calendario,
          //   icon: 'calendar_view_week',
          //   visible: true,
          // },
 
          // notificacoes: {
          //   id: 'calendario',
          //   text: 'CALENDÁRIO',
          //   path: urlsFichaAvaliacao.calendario,
          //   icon: 'material-icons-two-tone',
          //   visible: false,
          // },
          // noticias: {
          //   id: 'noticias',
          //   text: 'NOTÍCIAS',
          //   path: urlsFichaAvaliacao.noticias,
          //   icon: 'announcement',
          //   visible: false,
          // },
      }
    },

      academico: {
        id: 'academico',
        text: 'ACADÊMICO',
        type: 'title',
        visible: moduloDocenteGrupoAcademico == 1,
        itens: {
          // alunos: {
          //   id: 'alunos',
          //   text: 'ALUNOS',
          //   path: urlsFichaAvaliacao.alunos,
          //   icon: 'groups',
          //   visible: moduloDocenteGrupoAcademico == 1,
          // },
          turmas: {
            id: 'turmas',
            text: 'TURMAS',
            path: urlsFichaAvaliacao.turmas,
            icon: 'class',
            visible: moduloDocenteGrupoAcademico == 1,
          }
    }
},

    // diario: {
    //   id: 'academico',
    //   text: 'DIÁRIO',
    //   type: 'title',
    //   visible: moduloDocenteGrupoDiario == 1,
    //   itens: {
    //     quadro: {
    //       id: 'quadro',
    //       text: 'QUADRO DE HORÁRIO',
    //       path: urlsFichaAvaliacao.quadroHorario,
    //       icon: 'calendar_view_week',
    //       visible: moduloDocenteGrupoDiario == 1,
    //     },
    //     notas: {
    //       id: 'diario',
    //       text: 'NOTAS',
    //       path: urlsFichaAvaliacao.notas,
    //       icon: 'collections_bookmark',
    //       visible: moduloDocenteGrupoDiario == 1,
    //     },
    //     faltas: {
    //         id: 'faltas',
    //         text: 'FALTAS',
    //         path: urlsFichaAvaliacao.faltas,
    //         icon: 'spellcheck',
    //         visible: moduloDocenteGrupoDiario == 1,
    //       },
    //       boletim: {
    //         id: 'boletim',
    //         text: 'BOLETIM',
    //         path: urlsFichaAvaliacao.notasVisualizacao,
    //         icon: 'spellcheck',
    //         visible: false,
    //       },
    //       bnncFundamental:{
    //         id: 'bnnc-fundamentos',
    //         text: 'BNCC FUNDAMENTAL',
    //         path: urlsFichaAvaliacao.bnncFundamental,
    //         icon: 'collections_bookmark',
    //         visible: true,
    //       },
    //   }
    // }
    
})
}

export default linksFichaAvaliacao;