import { useMemo, useState } from 'react';
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import { toast } from 'react-toastify';
import urlsConfiguracoes from '../configuracoes/urlsConfiguracoes';
import { useQuery, useMutation } from 'react-query';
import { deleteUnidade, getUnidadesFiltroExportar, getUnidadesFiltro } from '../../services/unidades';
import { useNavigate } from "react-router-dom";
import Spinner from '../../components/spinner';
import handleDownloadExcel from '../../utils/handleDownloadExcel';

const UnidadesPage = () => {
  const navigate = useNavigate();

  const [descricao, setDescricao] = useState('');
  const [warningControl, setWarningControl] = useState(false)
  const nomeArquivo = 'unidades';

  const handleSearch = () => {
    refetch();
  }

  const exportarDados = async () => {
    
    if(data.length === 0){
      return toast.warning('Não há dados para exportar.')
    }
  
    try {

      debugger
  
    const relatorioData = await getUnidadesFiltroExportar('', 1)
  
    if (relatorioData) {
      return handleDownloadExcel(relatorioData, nomeArquivo);
    } else {
        return toast.error('Falha ao obter o relatório');
        // Tratar falha na obtenção do relatório
    }
    } catch (error) {
      return toast.error('Erro ao buscar relatório');
      // Tratar erro na chamada da função
    }
   
    // return await handleDownloadExcel(relatorioData, titulo);
  }

  const { data, isLoading: loading, refetch } = useQuery('getUnidadesFiltro', () => getUnidadesFiltro(descricao), {
  });

  console.log(data)

  const { mutate } = useMutation(deleteUnidade, {
    onSuccess: (message) => {
      toast.success(message);
      refetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar excluir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Sigla', accessor: 'sigla' },
      { Header: 'Endereço', accessor: 'endereco.logradouro' },
      { Header: 'Telefone', accessor: 'contato.telefone' },
      { Header: 'Email', accessor: 'contato.email' },
      {
        Header: 'Status', accessor: 'status',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.status ? 'success' : 'danger'}`}>
            {row.original.status ? 'Ativo' : 'Inativo'}
          </span>
        )
      },
      {
        Header: 'Lotação', accessor: 'unidadeLotacao',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.unidadeLotacao ? 'success' : 'danger'}`}>
            {row.original.unidadeLotacao ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: 'Locação', accessor: 'unidadeLocacao',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.unidadeLocacao ? 'success' : 'danger'}`}>
            {row.original.unidadeLocacao ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      { Header: 'Nome Diretor', accessor: 'nomeDiretor' },
      {
        Header: 'INEP', accessor: 'inep',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.inep ? 'success' : 'danger'}`}>
            {row.original.inep ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: 'Ações', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urlsConfiguracoes.unidadeEditar + row.original.id, {
              state: {
                id: row.original.id,
                unidade: {
                  nome: row.original.nome,
                  sigla: row.original.sigla,
                  endereco: row.original.endereco,
                  contato: row.original.contato,
                  status: row.original.status,
                  unidadeLotacao: row.original.unidadeLotacao,
                  unidadeLocacao: row.original.unidadeLocacao,
                  nomeDiretor: row.original.nomeDiretor,
                  inep: row.original.inep
                }
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons-two-tone">edit_note</i> 
            </button>
            {' '}
            {/* <button className='btn btn-sm btn-danger' onClick={() => {
              if (window.confirm('Deseja realmente excluir?')) {
                mutate(row.original.id);
              }
            }}>
              <i className="material-icons-two-tone">delete</i> excluir
            </button> */}
          </>
        )
      },
    ],
    [mutate, navigate]
  )

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Unidades'} itens={['Gestão', 'Secretaria', 'Unidades', 'Lista Geral']} actions={{ link: urlsConfiguracoes.unidadeAdicionar, text: "Nova Unidade" }} hasExcelButton
          onClickDownload={() => exportarDados()}  />
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 20
        }}
      >
        {warningControl && descricao.length < 3 && <span style={{ marginRight: '10px', alignSelf: 'center', color: '#CC7700' }}>Digite ao menos 3 caracteres para realizar a busca!</span>}
       
        <div className="form-group" style={{ width: '100%', maxWidth: 300 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Pesquisar por descrição"
            value={descricao}
            // onKeyUp={consultaCaracter}
            onChange={e => setDescricao(e.target.value)}
            // onMouseEnter={() => setWarningControl(true)}
            // onMouseOut={() => setWarningControl(false)}
          />
        </div>
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleSearch()}
          >
            <i className="material-icons-two-tone">search</i> Buscar
          </button>
        </div>
      </div>
      
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table columns={columns} data={data ? data : []} hasPagination hiddenColluns={['nomeDiretor', 'inep']} />
            )}
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  )

};

export default UnidadesPage;
