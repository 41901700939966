import { useMemo, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation, json } from 'react-router-dom';
import { toast } from 'react-toastify';
import urls from "./../../../utils/urls";
import { useMutation } from 'react-query';
import { createDiarioClasse, updateDiarioClasse, getQtdAvaliacoes } from './../../../services/diario-classe';
import { getTurnos as todosTurnos, getTurnosUnidadeEscolaridade } from './../../../services/turnos';
import { getEscolaridadesUnidade } from './../../../services/escolaridades';
import { getTurmasUnidadeEscolaridadeTurno } from './../../../services/turmas';
import { useQuery } from 'react-query'
import Table from '../../../components/table'
import removeEmpty from "./../../../utils/removeEmpty";
import upperCaseSimple from "./../../../utils/upperCaseSimple";
import Spinner from './../../../components/spinner';
import Breadcrumb from "./../../../components/breadcrumb";
import urlsGestaoUnidades from '../../gestao-unidades/urlsGestaoUnidades';
import BaseGestaoUnidades from './../../gestao-unidades/baseGestaoUnidades';
import BaseGestao from '../../gestao/baseGestao';
import BaseDocente from '../../docente/baseDocente';
import urlsGestao from '../../gestao/urlsGestao';
import urlsDocente from '../../docente/urlsDocente';
import { store } from '../../../store/index'
import { quadroHorarioServidorEscolaridade, quadroHorarioServidorTurno, quadroHorarioServidorTurma, quadroHorarioAulas } from './../../../services/quadro-horario';

const DiarioClasseAdicionarPage = (gestao) => {
  const unidade = localStorage.getItem('unidadeID');
  const navigate = useNavigate();
  const [escolaridade, setEscolaridade] = useState('');
  const [turno, setTurno] = useState('');
  const { id } = useParams();
  const { state } = useLocation();
  const [listaAvaliacoes, setListaAvaliacoes] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [labelCiclo, setLabelCiclo] = useState("");
  const [carregaLista, setCarregaLista] = useState(0);
  const currentUser = store.getState()['user']['user']
  const [idUnidade, setIdUnidade] = useState(localStorage.getItem('unidadeID'));
  const [idTurno, setIdTurno] = useState(0);
  const [idEscolaridade, setIdEscolaridade] = useState(0);
  const [idturma, setIdturma] = useState(0);

  const [periodo, setPeriodo] = useState(0);
  const [ciclo, setCiclo] = useState(0);
  const [ciclos, setCiclos] = useState(0);

  let BaseComponent;
  let BasePath;
  let avaliacoesUrl;
  let servidorId;

  // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão'
    avaliacoesUrl = urlsGestao.diarioClasseAvaliacoes
    servidorId = 0;
  }
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades'
    avaliacoesUrl = urlsGestaoUnidades.diarioClasseAvaliacoes
    servidorId = 0;
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente'
    avaliacoesUrl = urlsDocente.notasAvaliacoes
    servidorId = currentUser?.servidorId;
  }

  useEffect(() => {
    populaCiclo(ciclos);

  }, [unidade, ciclos])

  const onEscolaridadeChange = async (e) => {
    const newEscolaridade = e.target.value;
    setEscolaridade(newEscolaridade);
    setValue('idEscolaridade', newEscolaridade);

    setValue('idTurno', ''); // Clear dependent fields if needed
    setValue('idTurma', '');

    if (newEscolaridade != '' && newEscolaridade != null) {
      await refetchTurnos(); // Manually refetch turnos when escolaridade changes and meets the condition
    }
  };

  const { mutate, isLoading } = useMutation(id ? updateDiarioClasse : createDiarioClasse, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(-1);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar cadastrar Diario de Classe, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({
    mode: "onChange", defaultValues: {
      idEscolaridade: 0,
      idUnidade: unidade,
      idTurno: 0,
      turmaId: 0,
      periodo: 0,
      ciclos: 0,
      media: 0,
    }
  });

  const onSubmit = (data) => {
    if (id) {
      mutate({ id, data: upperCaseSimple(removeEmpty(data)) });
    } else {
      mutate(upperCaseSimple(removeEmpty(data)));
    }
  };

  useMemo(() => {
    (async () => {
      if (state) {
        if (id && state) {
          setValue('idEscolaridade', state.diarioClasse.idEscolaridade);
          setValue('idTurno', state.diarioClasse.idTurno);
          setValue('turmaId', state.diarioClasse.idTurma);
          setValue('periodo', state.diarioClasse.nomePeriodo);
          setValue('ciclos', state.diarioClasse.ciclos);
          setValue('media', state.diarioClasse.media);
          setCiclos(state.diarioClasse.ciclos);
          setPeriodo(state.diarioClasse.periodo);
          localStorage.setItem('diario', JSON.stringify(state));
        }
      }
    })();
  }, [id, state, setValue]);

  // const { data: escolaridades } = useQuery('getEscolaridadesUnidade', () => (getEscolaridadesUnidade(unidade)),
  //   {
  //     retry: 3,
  //     enabled: unidade !== null
  //   }
  // )

  // const { data: turnos, isLoading: loadingTurnos, refetch: refetchTurnos } =
  //   useQuery(['getTurnosUnidadeEscolaridade', unidade, escolaridade], () => {
  //     return getTurnosUnidadeEscolaridade(unidade, escolaridade);
  //   }, {
  //     enabled: !!escolaridade
  //   });
  const { data: escolaridades } = useQuery('quadroHorarioServidorEscolaridade', () => (quadroHorarioServidorEscolaridade(servidorId, idUnidade)),
    {
      enabled: true
    }
  )

  async function refetchTurmas(turno) {
    if (turno != 0) {
      var response = await quadroHorarioServidorTurma(servidorId, parseInt(idUnidade), parseInt(idEscolaridade), turno)
      // setLoadingTurmas(false)
      console.log(response, 'turmas123')
      setTurmas(response || [])
    }
  }

  const { data: turnos, refetch: refetchTurnos } = useQuery('quadroHorarioServidorTurno', () => quadroHorarioServidorTurno(servidorId, idUnidade, idEscolaridade),
    {
      enabled: true
    }
  )

  // const refetchTurmas = async (unidade, escolaridade, turno) =>{
  //   if(unidade !== '' && escolaridade !== '' && turno !== '')
  //   setTurmas(await getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno, 2024));
  // }

  // const { data: turmas, isLoading: loadingTurmas, refetch: refetchTurmas } = useQuery('getTurmasUnidadeEscolaridadeTurno', () =>
  //   unidade !== '' && escolaridade !== '' ? getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno, 2024) : null,
  //   {
  //     retry: 3,
  //   }
  // )

  useEffect(() => {
    async function fetchData() {
      try {

        await refetchTurmas();
      } catch (error) {
      }
    }
    fetchData();
  }, [turno])

  const populaAvaliacoes = async (ciclos) => {
    setListaAvaliacoes([]);
    var lista = [];
    for (let i = 0; i < ciclos; i++) {
      var qtdAvaliacoes = id == undefined ? 0 : await getQtdAvaliacoes(id, (i + 1));
      var avaliacao = {
        id: i + 1,
        ciclo: i + 1,
        nomeCiclo: (i + 1) + "º ",
        qtdAvaliacoes: id == undefined ? 0 : qtdAvaliacoes.data,
        descricao: "",
        pontuacaoMinima: 0,
        pontuacaoMaxima: 0
      };
      lista.push(avaliacao);
    }

    setListaAvaliacoes(lista);
  }

  const populaCiclo = (periodo) => {
    populaAvaliacoes(periodo);

  }

  // const editarAvaliacao = () => {
  //   for (let i = 0; i < listaAvaliacoes.length; i++) {
  //     if (listaAvaliacoes[i].ciclo == ciclo) {
  //       listaAvaliacoes[i].descricao = descricao;
  //       listaAvaliacoes[i].pontuacaoMinima = pontuacaoMinima;
  //       listaAvaliacoes[i].pontuacaoMaxima = pontuacaoMaxima;
  //       break;
  //     }
  //   }
  //   setListaAvaliacoes([...listaAvaliacoes]);
  //   setValue('academicoDiarioClasseAvaliacoes', listaAvaliacoes);

  //   cancelarAvaliacao();
  // }

  // const cancelarAvaliacao = () => {
  //   setCiclo(0);
  //   setDescricao("");
  //   setPontuacaoMinima(0);
  //   setPontuacaoMaxima(0);
  //   setEditandoAvaliacao(false);
  // }

  const getPeriodoDescricao = () => {
    switch (state?.diarioClasse.nomePeriodo) {
      case 'Mensal':
        return 'MÊS'; // Assuming you want to append this text
      case 'Bimestral':
        return 'BIMESTRE'; // Adjust these as needed
      case 'Trimestral':
        return 'TRIMESTRE';
      case 'Semestral':
        return 'SEMESTREE';
      case 'Anual':
        return 'ANO';
      default:
        return '';
    }
  };

  // console.log(state.diarioClasse.nomePeriodo)

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      // { Header: 'Ciclo', accessor: 'nomeCiclo' },
      {
        Header: 'Ciclo',
        accessor: 'nomeCiclo',
        Cell: ({ row }) => {
          // Make sure to call getPeriodoDescricao with the appropriate argument
          const descricao = getPeriodoDescricao();
          return `${row.original.nomeCiclo} ${descricao}`;
        }
      },
      { Header: 'Qtd. avaliações', accessor: 'qtdAvaliacoes' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
              id ? <button type='button' onClick={() => navigate(avaliacoesUrl + id, {
                state: {
                  id: id,
                  ciclo: row.original.ciclo,
                  state: state
                }
              })}
                className='btn btn-sm btn-warning'>
                <i className="material-icons-two-tone">edit_note</i> Avaliações
              </button>
                : <></>
            }
          </div>
        )
      },
    ],
    [navigate, isLoading, listaAvaliacoes]
  );

  return (
    <BaseComponent>
      <Breadcrumb title={'Diário de Classe'} itens={[BasePath, 'Diário de classe', 'Formulário']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-4'>
                      <label htmlFor="descricao" className="form-label">Escolaridade</label>
                      {id ?
                        <input type='text' readOnly className='form-control' {...register('idEscolaridade')} />
                        :
                        <select className="form-select" id="idEscolaridade" {...register('idEscolaridade', { required: true })}
                          onChange={async (e) => {
                            await setIdEscolaridade(e.target.value)
                            await setIdturma("");
                            await refetchTurnos();
                          }
                          } // Use the new handler function
                        >
                          <option value=""></option>
                          {escolaridades && escolaridades.map(item => (
                            <option key={item.id} value={item.id}>{item.descricao}</option>
                          ))}
                        </select>
                      }
                      {errors.idEscolaridade && (<span className="text-danger">Campo obrigatório</span>)}
                    </div>

                    <div className='col-4'>
                      <label htmlFor="descricao" className="form-label">Turno</label>
                      {id ?
                        <input type='text' readOnly className='form-control' {...register('idTurno')} />
                        :
                        <select className="form-select" id="idTurno" {...register('idTurno', { required: true })}
                          onChange={async e => {
                            setValue('idTurno', e.target.value)
                            setTurno(e.target.value)
                            setValue('turmaId', '')
                            await setIdTurno(e.target.value)
                            await setIdturma(0);
                            await refetchTurmas(parseInt(e.target.value));
                          }}>
                          <option value=""></option>
                          {turnos && turnos.map(item => (
                            <option key={item.id} value={item.id}>{item.descricao}</option>
                          ))}
                        </select>
                      }
                      {errors.idTurno && (<span className="text-danger">Campo obrigatório</span>)}
                    </div>

                    <div className='col-4'>
                      <label htmlFor="descricao" className="form-label">Turma</label>
                      {id ?
                        <input type='text' readOnly className='form-control' {...register('turmaId')} />
                        :
                        <select className="form-select" id="turmaId" {...register('turmaId', { required: true })}
                          onChange={e => {
                            setValue('turmaId', e.target.value)
                          }}>
                          <option value=""></option>
                          {turmas && turmas?.map(item => (
                            <option key={item.id} value={item.id}>{item.descricao}</option>
                          ))}
                        </select>
                      }
                      {errors.turmaId && (<span className="text-danger">Campo obrigatório</span>)}
                    </div>
                  </div>

                  <div className='row' style={{ marginTop: 30 }}>
                    <div className='col-4'>
                      <label htmlFor="descricao" className="form-label">Periodo</label>
                      {id ?
                        <input type='text' readOnly className='form-control' {...register('periodo')} />
                        :
                        <select className="form-select" id="periodo" {...register('periodo', { required: true })}
                          onChange={e => {
                            setValue('periodo', e.target.value)
                            // populaCiclo(e.target.value)
                          }}>
                          <option value="0"></option>
                          <option value="1">MENSAL</option>
                          <option value="2">BIMESTRAL</option>
                          <option value="3">TRIMESTRAL</option>
                          <option value="4">SEMESTRAL</option>
                          <option value="5">ANUAL</option>
                        </select>
                      }
                      {errors.periodo && (<span className="text-danger">Campo obrigatório</span>)}
                    </div>

                    <div className='col-4'>
                      <label htmlFor="descricao" className="form-label">Valor periodo</label>
                      <input type='number' readOnly={id ? true : false} onKeyUp={(e) => {
                        setCiclo(e.target.value);
                        populaCiclo(e.target.value)
                      }} className='form-control' {...register('ciclos')} />
                    </div>

                    {/* <div className='col-4'>
                      <label htmlFor="descricao" className="form-label">Média</label>
                      <input type='text' readOnly={id ? true : false} className='form-control' id="media" {...register('media', { required: true })} />
                      {errors.media && (<span className="text-danger">Campo obrigatório</span>)}
                    </div> */}
                  </div>
                </>
              </div>

              <hr />

              <div className="row">
                <Table columns={columns} data={listaAvaliacoes} selected={selectedRow} />
              </div>

              <div className='card-footer text-end'>
                <button type='submit' className='btn btn-success' disabled={!isValid || isLoading}>
                  {isLoading ? 'enviando...' : id ? 'Atualizar' : 'Cadastrar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseComponent >
  )

};

export default DiarioClasseAdicionarPage;