import api from '../../'
import { toast } from 'react-toastify';

const token = localStorage.getItem('access_token');



async function cadastrarInscricaoChamadaPublica(data) {
  return await api
    .post('chamadapublica', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });

    
}

async function postEnturmar(data) {
  return await api
    .post('chamadapublica/enturmar', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function listarUnidadesChamadaPublica(bairro, escolaridade) {
  return await api
    .get(`chamadapublica/unidades?bairro=${bairro}&escolaridade=${escolaridade}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function listarFiltroUnidades(tipo) {
  return await api
    .get(`chamadapublica/filtroUnidades?tipo=${tipo}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function listarFiltroEscolaridades(tipo, unidade = 0) {
  return await api
    .get(`chamadapublica/filtroEscolaridades?tipo=${tipo}&unidadeId=${unidade}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function listarInscricoes(unidade, escolaridade, status, deficiente, estudanteDaRede, skip, limit, nome, exportar = 0) {

  let config = {};

  if (exportar === 1) {
    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };
  }

  return await api
    .get(`chamadapublica/inscricoes?unidade=${unidade}&escolaridade=${escolaridade}&status=${status}&deficiente=${deficiente}&estudanteDaRede=${estudanteDaRede}&skip=${skip}&limit=${limit}&nome=${nome}&exportar=${exportar}`, config,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function listarInscricoesRemanejamento(unidade, escolaridade, status, deficiente, estudanteDaRede, skip, limit, nome) {
  return await api
    .get(`chamadapublica/inscricoesRemanejamento?unidade=${unidade}&escolaridade=${escolaridade}&status=${status}&deficiente=${deficiente}&estudanteDaRede=${estudanteDaRede}&skip=${skip}&limit=${limit}&nome=${nome}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function listarTotalConvocados(unidade, escolaridade) {
  return await api
    .get(`chamadapublica/totalConvocados?unidade=${unidade}&escolaridade=${escolaridade}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function getInscricoesRelatorio(unidadeId = 0, escolaridadeId = 0, turnoId= 0, alunoRede= 0, deficiente = 0, bairro = '', skip = 0, limit = 20, exportar = 0, status = 1) {
  
  let config = {};

  // Se exportar for 1, ajusta a configuração para resposta do tipo 'arraybuffer'
  if (exportar === 1) {
    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };
  }

  return await api
    .get(`chamadapublica/relatorios?unidadeId=${unidadeId}&escolaridadeId=${escolaridadeId}&turnoId=${turnoId}&alunoRede=${alunoRede}&deficiente=${deficiente}&bairro=${bairro}&skip=${skip}&limit=${limit}&exportar=${exportar}&status=${status}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getInscricoesRelatorioNominais(dataInicial, dataFinal, skip = 0, limit = 20, exportar = 0, status = 1) {
  
  let config = {};

  // Se exportar for 1, ajusta a configuração para resposta do tipo 'arraybuffer'
  if (exportar === 1) {
    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };
  }

  return await api
    .get(`chamadapublica/relatorios/nominais?dataInicial=${dataInicial}&dataFinal=${dataFinal}&skip=${skip}&limit=${limit}&exportar=${exportar}&status=${status}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getInscricoesRelatorioQuantitativos(dataInicial, dataFinal, skip = 0, limit = 20, exportar = 0, status = 1) {
  
  let config = {};

  // Se exportar for 1, ajusta a configuração para resposta do tipo 'arraybuffer'
  if (exportar === 1) {
    config = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' }
    };
  }

  return await api
    .get(`chamadapublica/relatorios/quantitativo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&skip=${skip}&limit=${limit}&exportar=${exportar}&status=${status}`, config)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarMatriculas(unidade, skip, limit, nome) {
  return await api
    .get(`chamadapublica/matriculas?unidade=${unidade}&skip=${skip}&limit=${limit}&nome=${nome}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function getDashboard(unidade, escolaridade, skip, limit) {
  return await api
    .get(`estatistica/chamadaPublica`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data.data;
    });
}

async function listaEspera(unidade, escolaridade, skip, limit) {
  return await api
    .get(`chamadapublica/listaEspera?unidade=${unidade}&escolaridade=${escolaridade}&skip=${skip}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function getVagas(skip = 0, limit = 20) {
  return await api
    .get(`chamadapublica/vagas?skip=${skip}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function getRelatorioVagas() {
  try {
    const response = await api.get(
      'chamadapublica/relatorio/vagas',
      { responseType: 'arraybuffer' },
      { headers: { 'Content-Type': 'blob' } }
    );

    if (response.status === 200) {
      let fileName = `vagas.xlsx`;

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: 'application/pdf',
            encoding: 'UTF-8',
          }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            encoding: 'UTF-8',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } else {
      // Handle non-200 status codes here
      console.error('HTTP Error:', response.status);
      toast.error('Ocorreu um erro ao exportar os dados.');
    }
  } catch (error) {
    // Handle other errors here
    console.error('Error:', error);
    toast.error('Ocorreu um erro ao exportar os dados.');
  }
}

async function convocarInscricoes(data) {
  return await api
    .post('chamadapublica/convocar', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function postIndeferirInscricao(data) {
  return await api
    .post('chamadapublica/indeferir', data)
    .then(async (res) => {
      return res;
    });
}

async function pesquisaNome(nome, skip, limit) {
  
  return await api
    .get(`chamadapublica/pesquisa?nome=${nome}&skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};



async function getConvocacoes(escolaridadeId, skip, limit) {
  
  return await api
    .get(`chamadapublica/convocacoes?escolaridadeId=${escolaridadeId}&skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function getHistorico(id, opcao) {
  
    return await api
      .get(`chamadapublica/historico?id=${id}&opcao=${opcao}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function postListaEspera(data) {
    return await api
      .post('listaEspera', data, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      })
      .then(async (res) => {
        const data = res.data;
        return data;
      });
  };

  async function postConvocar(data) {
    return await api
      .post('chamadapublica/convocar', data, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      })
      .then(async (res) => {
        const data = res.data;
        return data;
      });
  };
  
export {
  cadastrarInscricaoChamadaPublica,
  listarUnidadesChamadaPublica,
  listarFiltroUnidades,
  listarFiltroEscolaridades,
  listarInscricoes,
  convocarInscricoes,
  getDashboard,
  getVagas,
  getRelatorioVagas,
  listaEspera,
  postIndeferirInscricao,
  postEnturmar,
  pesquisaNome,
  getHistorico,
  postListaEspera,
  listarMatriculas,
  getInscricoesRelatorio,
  getConvocacoes,
  postConvocar,
  listarTotalConvocados,
  listarInscricoesRemanejamento,
  getInscricoesRelatorioQuantitativos,
  getInscricoesRelatorioNominais
}