import { useMemo, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation, json } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "./../../../../components/breadcrumb";
import BaseGestaoUnidades from './../../../gestao-unidades/baseGestaoUnidades';
import urls from "./../../../../utils/urls";
import { useMutation } from 'react-query';
import { createDiarioClasse, updateDiarioClasse, getAvaliacoes, getAlunosParaAvaliacoes, getDisciplina, postLanctos } from './../../../../services/diario-classe';
import { getTurnos as todosTurnos, getTurnosUnidadeEscolaridade } from './../../../../services/turnos';
import { getEscolaridades } from './../../../../services/escolaridades';
import { getTurmasUnidadeEscolaridadeTurno } from './../../../../services/turmas';
import { getDisciplinasByTurmaServidor } from './../../../../services/disciplinas';
import { quadroHorarioServidorEscolaridade, quadroHorarioServidorTurno, quadroHorarioServidorTurma, quadroHorarioAulas } from './../../../../services/quadro-horario';

import { useQuery } from 'react-query'
import BaseGestao from '../../../gestao/baseGestao';
import BaseDocente from '../../../docente/baseDocente';
import { store } from '../../../../store/index'
import Select, { AriaOnFocus } from 'react-select';

const DiarioClasseAvaliacoesAlunosPage = (gestao) => {
    const [idUnidade, setIdUnidade] = useState(localStorage.getItem('unidadeID'));
    const [dataTurma, setDataTurma] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const [labelCiclo, setLabelCiclo] = useState("");
    const [escolaridade, setEscolaridade] = useState("");
    const [turno, setTurno] = useState("");
    const [turma, setTurma] = useState("");
    const [periodo, setPeriodo] = useState("");
    const [avaliacao, setAvaliacao] = useState("");
    const [ciclo, setCiclo] = useState("");
    const [disciplina, setDisciplina] = useState("");
    const [listaCiclo, setListaCiclo] = useState([]);
    const [disciplinas, setDisciplinas] = useState([]);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [dataAvaliacoes, setDataAvaliacoes] = useState([]);
    const [alunos, setAlunos] = useState([]);
    const [pontuacoes, setPontuacoes] = useState({});
    const currentUser = store.getState()['user']['user']
    const [isOpen, setIsOpen] = useState(false);
    const onMenuOpen = () => setIsOpen(true);
    const onMenuClose = () => setIsOpen(false);

    const [isOpenDisciplina, setIsOpenDisciplina] = useState(false);
    const onMenuOpenDisciplina = () => setIsOpenDisciplina(true);
    const onMenuCloseDisciplina = () => setIsOpenDisciplina(false);

    const [isOpenAvaliacao, setIsOpenAvaliacao] = useState(false);
    const onMenuOpenAvaliacao = () => setIsOpenAvaliacao(true);
    const onMenuCloseAvaliacao = () => setIsOpenAvaliacao(false);
    const [idTurno, setIdTurno] = useState(state.diarioClasse.idTurno);
    const [idEscolaridade, setIdEscolaridade] = useState(state.diarioClasse.idEscolaridade);
    const [idturma, setIdturma] = useState(state.diarioClasse.idTurma);


    let BaseComponent;
    let BasePath;
    let servidorId = 0;

    // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
    if (gestao.gestao) {
        BaseComponent = BaseGestao;
        BasePath = 'Gestão'
        servidorId = 0;
    }
    if (!gestao.gestao && !gestao.docente) {
        BaseComponent = BaseGestaoUnidades;
        BasePath = 'Gestão Unidades'
        servidorId = 0;
    }

    // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
    if (gestao.docente) {
        BaseComponent = BaseDocente;
        BasePath = 'Docente'
        servidorId = currentUser?.servidorId;
    }
    const [ciclos, setCiclos] = useState(0);

    const populaCiclo = (periodo, ciclo) => {
        var lista = [];
        if (periodo === "1") {
            for (let i = 1; i <= ciclo; i++)
                lista.push({ value: i, label: `${i}º MÊS` });
            setListaCiclo(lista);
        } else if (periodo === "2") {
            for (let i = 1; i <= ciclo; i++)
                lista.push({ value: i, label: `${i}º BIMESTRE` });
            setListaCiclo(lista);
        } else if (periodo === "3") {
            for (let i = 1; i <= ciclo; i++)
                lista.push({ value: i, label: `${i}º TRIMESTRE` });
            setListaCiclo(lista);
        } else if (periodo === "4") {
            for (let i = 1; i <= ciclo; i++)
                lista.push({ value: i, label: `${i}º SEMESTRE` });
            setListaCiclo(lista);
        } else if (periodo === "5") {
            for (let i = 1; i <= ciclo; i++)
                lista.push({ value: i, label: `${i}º ANO` });
            setListaCiclo(lista);
        } else {
            setCiclos('');
        }
    }

    const { mutate, isLoading } = useMutation(id ? updateDiarioClasse : createDiarioClasse, {
        onSuccess: (message) => {
            toast.success(message);
            navigate(urls.gestaoUnidadesDiarioClasse);
        },
        onError: (error) => {
            if (error.response) {
                const { message } = error.response.data;
                toast.error(message);
            } else if (error.request) {
                toast.error('Erro ao tentar cadastrar Diario de Classe, tente novamente mais tarde.');
            } else {
                toast.error(error.message);
            }
        }
    });

    const { data: escolaridades } = useQuery('quadroHorarioServidorEscolaridade', () => (quadroHorarioServidorEscolaridade(servidorId, idUnidade)),
        {
            onSuccess: async () => {
                await fetchTurnos();
            },
            enabled: true
        }
    )

    const fetchTurnos = async () => {
        setTurnos(await quadroHorarioServidorTurno(servidorId, idUnidade, idEscolaridade));

        await fetchTurma();
    }

    const refetchavaliacoes = async (idCiclo) => {
        if(idUnidade !== '' && idEscolaridade !== 0 && idTurno !== 0 && idturma !== 0 && periodo !== ''){
            var data = await getAvaliacoes(idUnidade, idEscolaridade, idTurno, idturma, periodo, idCiclo); 
            setDataAvaliacoes(data);
            var lista = [];
            for (let i = 0; i < data?.length; i++) {
                lista.push({ value: data[i].id, label: data[i].descricao });
            }

            setAvaliacoes(lista);
        }
        
    }

    const fetchTurma = async () => {
        setDataTurma(await quadroHorarioServidorTurma(servidorId, parseInt(idUnidade), parseInt(idEscolaridade), idTurno));
    }

    const { data: dataDisciplinas, isLoading: loadingDisciplinas, refetch: refetchDisciplina } = useQuery('getDisciplinasByTurmaServidor', () =>
        getDisciplinasByTurmaServidor(idturma, servidorId),
        {
            onSuccess: async (data) => {
                var lista = [];
                for (let i = 0; i < data.length; i++) {
                    lista.push({ value: data[i].id, label: data[i].descricao });
                }

                setDisciplinas(lista);
            },
            retry: 3
        }
    )

    const populaAlunos = async (avaliacaoSelected) => {
        var lista = await getAlunosParaAvaliacoes(idUnidade, idEscolaridade, idTurno, idturma, avaliacaoSelected, disciplina);
        var listaAlunos = [];
        for (let i = 0; i < lista.data.length; i++) {
            const element = lista.data[i];
            var item = { id: element.id, nome: element.nome, nota: element.nota, matricula: element.matricula };
            listaAlunos.push(item);
        }
        setAlunos(listaAlunos);
        return listaAlunos;
    }

    const resetNotas = (listaAlunos) => {
        listaAlunos.forEach(element => {
            document.getElementById("nota" + element.id).value = element.nota;
        });
    }

    const salvarAtualizarLancto = async (id) => {
        if (disciplina === "") {
            toast.error("Selecione a disciplina!");
            return;

        }
        var model = {
            id: 0,
            idAvaliacao: avaliacao,
            idAluno: id,
            nota: document.getElementById("nota" + id).value || 0,
            idDisciplina: disciplina,
            dataCriacao: new Date(),
            ultimaAtualizacao: new Date(),
            usuarioAlteracao: JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user).user.id
        }

        var response = await postLanctos(model);
        if (response.statusCode == 200)
            toast.success("Lançamento da nota realizado com sucesso!");
        else
            toast.error("Houve um problema ao realizar o Lancamento da nota!");
    }

    useMemo(() => {
        (async () => {
            if (state) {
                console.log(state);
                if (JSON.parse(localStorage.getItem('unidadeID')) != state.diarioClasse.idUnidade && state.diarioClasse.idUnidade != undefined) {
                    debugger;
                    localStorage.setItem('unidadeID', state.diarioClasse.idUnidade);
                    window.location.reload();
                }
                setEscolaridade(state.diarioClasse.idEscolaridade);
                setTurno(state.diarioClasse.idTurno);
                setTurma(state.diarioClasse.idTurma);
                setPeriodo(state.diarioClasse.periodo);
                populaCiclo(state.diarioClasse.periodo.toString(), parseInt(state.diarioClasse.ciclos));
                // setCiclo(state.diarioClasse.ciclos);
                // await refetchavaliacoes();
            }
        })();
    }, [id, state]);

    return (
        <BaseComponent>
            <Breadcrumb title={'Avaliações/Alunos'} itens={[BasePath, 'Diário de classe', 'Avaliações', 'Alunos']} />
            <form>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className="card-body">
                                <>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label htmlFor="descricao" className="form-label">ESCOLARIDADE</label>
                                            {
                                                <select className="form-select" disabled={true} id="idEscolaridade" value={idEscolaridade}
                                                    onChange={async e => {
                                                        await setIdEscolaridade(e.target.value)
                                                        await setIdturma("");
                                                        await fetchTurnos();
                                                    }}>
                                                    <option value={0}>Selecione</option>
                                                    {escolaridades && escolaridades.map(item => (
                                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>

                                        <div className='col-3'>
                                            <label htmlFor="descricao" className="form-label">TURNO</label>
                                            {
                                                <select className="form-select" disabled={true} id="idTurno" value={idTurno}
                                                    onChange={async e => {
                                                        await setIdTurno(e.target.value)
                                                        await setIdturma(0);
                                                        await fetchTurma(parseInt(e.target.value));
                                                    }}>
                                                    <option value={0}></option>
                                                    {turnos && turnos.map(item => (
                                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>

                                        <div className='col-3'>
                                            <label htmlFor="descricao" className="form-label">TURMA</label>
                                            {
                                                <select className="form-select" disabled={true} id="turmaId" value={idturma}
                                                    onChange={async e => {
                                                        await setIdturma(e.target.value);
                                                        await refetchavaliacoes();
                                                    }}>
                                                    <option value=""></option>
                                                    {dataTurma && dataTurma?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>

                                        <div className='col-3'>
                                            <label htmlFor="descricao" className="form-label">PERÍODO</label>
                                            {
                                                <select className="form-select" disabled={true} id="periodo" value={periodo}
                                                    onChange={async e => {
                                                        await setPeriodo(e.target.value);
                                                        await populaCiclo(e.target.value);
                                                    }}>
                                                    <option value="0">Selecione</option>
                                                    <option value="1">MENSAL</option>
                                                    <option value="2">BIMESTRAL</option>
                                                    <option value="3">TRIMESTAL</option>
                                                    <option value="4">SEMESTRAL</option>
                                                    <option value="5">ANUAL</option>
                                                </select>
                                            }
                                        </div>



                                    </div>

                                    <div className='row' style={{ marginTop: 30 }}>

                                        <div className='col-4'>
                                            <label htmlFor="descricao" className="form-label">CICLO</label>
                                            {
                                                <Select
                                                    aria-labelledby="aria-label"
                                                    inputId="aria-example-input"
                                                    name="aria-live-color"
                                                    onMenuOpen={onMenuOpen}
                                                    onMenuClose={onMenuClose}
                                                    options={listaCiclo}
                                                    value={listaCiclo.find(x => x.value == ciclo)}
                                                    onChange={async e => {
                                                        await setCiclo(e.value);
                                                        await refetchavaliacoes(e.value);
                                                        await refetchDisciplina();
                                                    }
                                                    }
                                                />
                                            }
                                        </div>

                                        <div className='col-4'>
                                            <label htmlFor="descricao" className="form-label">DISCIPLINA</label>
                                            {
                                                <Select
                                                    aria-labelledby="aria-label"
                                                    inputId="aria-example-input"
                                                    name="aria-live-color"
                                                    onMenuOpen={onMenuOpenDisciplina}
                                                    onMenuClose={onMenuCloseDisciplina}
                                                    options={disciplinas}
                                                    value={disciplinas.find(x => x.value == disciplina)}
                                                    onChange={async e => {
                                                        await setDisciplina(e.value);
                                                    }
                                                    }
                                                />
                                            }
                                        </div>

                                        <div className='col-4'>
                                            <label htmlFor="descricao" className="form-label">AVALIAÇÃO</label>
                                            {

                                                <Select
                                                    aria-labelledby="aria-label"
                                                    inputId="aria-example-input"
                                                    name="aria-live-color"
                                                    onMenuOpen={onMenuOpenAvaliacao}
                                                    onMenuClose={onMenuCloseAvaliacao}
                                                    options={avaliacoes}
                                                    value={avaliacoes.find(x => x.value == avaliacao)}
                                                    onChange={async e => {
                                                        setAvaliacao(e.value);
                                                        if (e.value != "") {
                                                            var listaDados = await populaAlunos(e.value);
                                                            setTimeout(() => {
                                                                setPontuacoes({ pontuacaoMinima: dataAvaliacoes.find(x => x.id == e.value).pontuacaoMinima, pontuacaoMaxima: dataAvaliacoes.find(x => x.id == e.value).pontuacaoMaxima });

                                                                resetNotas(listaDados);
                                                            }, 1000);
                                                        }
                                                    }
                                                    }
                                                />
                                                // <select className="form-select" id="idEscolaridade" value={avaliacao}
                                                //     onChange={async e => {
                                                //         setAvaliacao(e.target.value);
                                                //         if (e.target.value != "") {
                                                //             var listaDados = await populaAlunos(e.target.value);
                                                //             setTimeout(() => {
                                                //                 setPontuacoes({ pontuacaoMinima: avaliacoes.find(x => x.id == e.target.value).pontuacaoMinima, pontuacaoMaxima: avaliacoes.find(x => x.id == e.target.value).pontuacaoMaxima });

                                                //                 resetNotas(listaDados);
                                                //             }, 1000);
                                                //         }
                                                //     }}>
                                                //     <option value="">Selecione</option>
                                                //     {avaliacoes && avaliacoes.map(item => (
                                                //         <option key={item.id} value={item.id}>{item.descricao}</option>
                                                //     ))}
                                                // </select>
                                            }
                                        </div>
                                    </div>
                                </>

                                <div className='row' style={{ marginTop: 30 }}>
                                    <div className='col-12'>
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover table-responsive">
                                                <thead>
                                                    <tr className="text-center">
                                                        <th className="text-center">MATRÍCULA</th>
                                                        <th className="text-center">NOME</th>
                                                        <th className="text-center">NOTA</th>
                                                        {/* <th className="text-center"></th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        alunos.map((row) => {
                                                            return (
                                                                <tr className="text-center">
                                                                    <td>{row.matricula}</td>
                                                                    <td>{row.nome}</td>
                                                                    <td>
                                                                        <input
                                                                            type='number'
                                                                            id={'nota' + row.id}
                                                                            className='form-control'
                                                                            onBlur={(e) => salvarAtualizarLancto(row.id, e.target.value)}
                                                                        />
                                                                    </td>
                                                                    {/* <td> */}
                                                                    {/* Button can be removed if not needed, or used for a different purpose */}
                                                                    {/* <button
                                                                    type='button'
                                                                    disabled={avaliacao === ""}
                                                                    style={{ marginTop: 4, float: 'left' }}
                                                                    className='btn btn-info'
                                                                  >
                                                                    Salvar
                                                                  </button> */}
                                                                    {/* </td> */}
                                                                </tr>
                                                            );
                                                        })

                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </BaseComponent >
    )

};

export default DiarioClasseAvaliacoesAlunosPage;