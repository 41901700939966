import api from "..";

async function getEscolaridades() {
  return await api
    .get('escolaridade')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function deleteEscolaridade(id) {
  return await api
    .delete(`escolaridade/${id}`)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function createEscolaridade(data) {
  return await api
    .post('escolaridade', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function updateEscolaridade({ id, data }) {
  return await api
    .put(`escolaridade/${id}`, data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function getEscolaridade(id) {
  return await api
    .get(`escolaridade/${id}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function getEscolaridadesUnidade(unidade) {
    return await api
      .get(`escolaridade/unidade/${unidade}`)
      .then(async (res) => {
        const { data } = res.data;
        return data;
      });
  };

export {
  getEscolaridades,
  deleteEscolaridade,
  createEscolaridade,
  updateEscolaridade,
  getEscolaridade,
  getEscolaridadesUnidade
}