import { useEffect, useMemo, useState } from 'react';
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseGestaoUnidades from '../gestao-unidades/baseGestaoUnidades';
import BaseGestao from '../gestao/baseGestao';
import urlsGestao from '../gestao/urlsGestao';
import urlsDocente from '../docente/urlsDocente';
import BaseDocente from '../docente/baseDocente';
import urlsGestaoUnidades from '../gestao-unidades/urlsGestaoUnidades';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { getDiarioClasse, deleteDiarioClasse } from './../../services/diario-classe';
import { useNavigate } from "react-router-dom";
import Spinner from './../../components/spinner';
import { store } from '../../store';

const DiarioClassePage = (gestao) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const unidadeId = localStorage.getItem('unidadeID')
  const currentUser = store.getState()['user']['user']
  //   const editarUrl = gestao.gestao ? urlsGestao.alunosEditar : urlsGestaoUnidades.alunosEditar;
  //   const adicionarUrl = gestao.gestao ? urlsGestao.alunosAdicionar : urlsGestaoUnidades.alunosAdicionar;
  //   const evadirUrl = gestao.gestao ? urlsGestao.alunosEvadir : urlsGestaoUnidades.alunosEvadir;
  //   const aprovacaoUrl = gestao.gestao ? urlsGestao.aprovacaoUrl : urlsGestaoUnidades.aprovacaoUrl;
  //   const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  let BaseComponent;
  let BasePath;
  let adicionarUrl;
  let editarUrl;
  let servidorId;
  let notasAvaliacoesAlunosUrl = urlsGestaoUnidades.diarioClasseAvaliacoesAlunos

  // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão'
    adicionarUrl = urlsGestao.notasAvaliacoesAlunos
    editarUrl = urlsDocente.notasAdicionar
    servidorId = 0

  }
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades'
    adicionarUrl = urlsGestaoUnidades.diarioClasseAdicionar
    // editarUrl = urlsGestaoUnidades.notasAdicionar
    servidorId = 0
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente'
    adicionarUrl = urlsDocente.notasAvaliacoesAlunos
    editarUrl = urlsDocente.notasAdicionar
    servidorId = currentUser?.servidorId
  }

  const { isLoading: loading, refetch } = useQuery('getDiarioClasse', () => getDiarioClasse(unidadeId, servidorId, skip, limit), {
    onSuccess: (data) => {
      setData(data);
      // setTotal(data.total);
      // setLimit(data.limit);
      // setSkip(data.skip);
    },
    enabled: false,
    retry: true
  })

  useEffect(() => {
    refetch()
  }, [refetch, limit, skip])

  const { mutate } = useMutation('', {
    onSuccess: (message) => {
      toast.success(message);
      refetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar excluir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Escolaridade', accessor: 'nomeEscolaridade' },
      { Header: 'Unidade', accessor: 'nomeUnidade' },
      { Header: 'Turma', accessor: 'nomeTurma' },
      { Header: 'Periodo', accessor: 'nomePeriodo' },
      { Header: 'Ciclos', accessor: 'ciclos' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(notasAvaliacoesAlunosUrl + row.original.id, {
              state: {
                id: row.original.id,
                diarioClasse: {
                  idUnidade: row.original.idUnidade,
                  idEscolaridade: row.original.idEscolaridade,
                  idTurno: row.original.idTurno,
                  idTurma: row.original.turmaId,
                  escolaridadeNome: row.original.nomeEscolaridade,
                  turnoNome: row.original.nomeTurno,
                  turmaNome: row.original.nomeTurma,
                  periodo: row.original.periodo,
                  ciclos: row.original.ciclos,
                  nomePeriodo: row.original.nomePeriodo,
                  media: row.original.media,
                }
              }
            })} className='btn btn-sm btn-success'>
              <i className="material-icons-two-tone">edit_note</i> Notas
            </button>
            {
              (gestao.gestao === true || gestao.docente === false) &&
              <button
                onClick={() => navigate(editarUrl + row.original.id, {
                  state: {
                    id: row.original.id,
                    diarioClasse: {
                      row: row.original,
                      idEscolaridade: row.original.nomeEscolaridade,
                      idTurno: row.original.nomeTurno,
                      idTurma: row.original.nomeTurma,
                      nomePeriodo: row.original.nomePeriodo,
                      periodo: row.original.periodo,
                      ciclos: row.original.ciclos,
                      media: row.original.media,
                      academicoDiarioClasseAvaliacoes: row.original.academicoDiarioClasseAvaliacoes
                    }
                  }
                })}
                className='btn btn-sm btn-warning'
              >
                <i className="material-icons-two-tone">edit_note</i> editar
              </button>
            }

            {/* <button className='btn btn-sm btn-danger' onClick={() => {
                if (window.confirm('Deseja realmente excluir?')) {
                  mutate(row.original.id);
                }
              }}>
                <i className="material-icons-two-tone">delete</i> excluir
              </button> */}
          </>
        )
      },
    ],
    [mutate, navigate]
  )

  return (
    <BaseComponent>
      {
        gestao.docente ?
          <Breadcrumb title={'Diário de classe'} itens={[BasePath, 'Acadêmico', 'Diário Classe']} />
          :
          <Breadcrumb title={'Diário de classe'} itens={[BasePath, 'Acadêmico', 'Diário Classe', 'Lista Geral']} actions={{ link: urlsGestaoUnidades.diarioClasseAdicionar, text: "Novo Diário de classe" }} />
      }

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={data?.total}
              />
            )}
          </div>
        </div>
      </div>
    </BaseComponent>
  )

};

export default DiarioClassePage;
