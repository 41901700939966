import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import urlsConfiguracoes from '../configuracoes/urlsConfiguracoes';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { getServidoresCargos, relatorioCargos } from '../../services/cargos';
import { useNavigate } from "react-router-dom";
import Spinner from '../../components/spinner';

const CargosPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [ativo, setAtivo] = useState(true);
  const [descricao, setDescricao] = useState('');
  const [warningControl, setWarningControl] = useState(false)

  const { isLoading: loading, refetch } = useQuery('getServidoresCargos', () => getServidoresCargos(ativo, skip, limit, descricao), {
    onSuccess: (data) => {
      setData(data.data);
      setTotal(data.total);
      setLimit(data.limit);
      setSkip(data.skip);
    },
    enabled: false,
    retry: true
  })

  const { data: dataReport } = useQuery('relatorioCargos', () => relatorioCargos(), {
    onSuccess: (data) => {
    },
    enabled: false,
    retry: true
  });

  useEffect(() => {
    refetch();
  }, [data, limit, skip, descricao, ativo]);

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      {
        Header: 'Status', accessor: 'ativo',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.ativo ? 'success' : 'danger'}`}>
            {row.original.ativo ? 'Ativo' : 'Inativo'}
          </span>
        )
      },
      { Header: 'Contagem', accessor: 'contagem' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urlsConfiguracoes.cargoEditar + row.original.id, {
              state: {
                id: row.original.id,
                cargo: {
                  descricao: row.original.descricao,
                  ativo: row.original.ativo
                }
              }
            })} className='btn btn-sm btn-warning'>
              <i className="material-icons-two-tone">edit_note</i> editar
            </button>
          </>
        )
      },
    ],
    [navigate, data]
  )

  const handleSearch = () => {
    refetch();
  }

  const consultaCaracter = () => {
    if (descricao.length >= 3) {
      refetch();
    }
  }

  const handleDownloadExcel = async () => {
    var result = relatorioCargos().then(response => {
      let fileName = `cargos.xlsx`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: 'application/pdf',
            encoding: 'UTF-8'
          }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            encoding: 'UTF-8'
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });

  }

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Servidor Cargos'} itens={['Gestão', 'Parâmetros', 'Cargos', 'Lista Geral']} hasExcelButton
        onClickDownload={() => handleDownloadExcel()} actions={{ link: urlsConfiguracoes.cargoAdicionar, text: "Novo Cargo" }} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 20
        }}
      >
        {warningControl && descricao.length < 3 && <span style={{ marginRight: '10px', alignSelf: 'center', color: '#CC7700' }}>Digite ao menos 3 caracteres para realizar a busca!</span>}
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginRight: 10,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id="ativo"
            checked={ativo}
            onChange={e => setAtivo(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="ativo">
            Cargos ativos
          </label>
        </div>
        <div className="form-group" style={{ width: '100%', maxWidth: 300 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Pesquisar por descrição"
            value={descricao}
            onKeyUp={consultaCaracter}
            onChange={e => setDescricao(e.target.value)}
            onMouseEnter={() => setWarningControl(true)}
            onMouseOut={() => setWarningControl(false)}
          />
        </div>
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleSearch()}
          >
            <i className="material-icons-two-tone">search</i> Buscar
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={data}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={total}
              />
            )}
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  )

};

export default CargosPage;
