import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsBilingue from '../../../pages/processos/bilingue/private/urlsBilingue';
import HomeBilingue from '../../../pages/processos/bilingue/private/homeBilingue';
import BilingueInscricoes from '../../../pages/processos/bilingue/private/inscricoes';
import BilingueIndeferir from '../../../pages/processos/bilingue/private/inscricoes/indeferir';
import BilingueVisualizarFormulario from '../../../pages/processos/bilingue/public/visualizacaoForm';
import ProcessoSeletivoBilingue from '../../../pages/processos/bilingue/public';
import ProcessoSeletivoCivico from '../../../pages/processos/bilingue/public/civico';
import ProcessoSeletivoGastronomia from '../../../pages/processos/bilingue/public/gastronomia';
import ProcessoSeletivo2024Form from '../../../pages/processos/bilingue/public/2024';

const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';

const BilingueRoutes = () => (
    <React.Fragment>
    {enable && (
    <>
      <Route path={urlsBilingue.dashboard} element={<HomeBilingue />} />
      <Route path={urlsBilingue.inscricoes} element={<BilingueInscricoes />} />
      {/* <Route path={urlsBilingue.formularioCivico} element={<ProcessoSeletivoCivico />} />
        <Route path={urlsBilingue.formularioGastronomia} element={<ProcessoSeletivoGastronomia />} /> */}
        <Route path={urlsBilingue.formulario2024} element={<ProcessoSeletivo2024Form />} />
        <Route path={urlsBilingue.formulario} element={<ProcessoSeletivoBilingue />} />
      <Route path={urlsBilingue.visualizarId} element={<BilingueVisualizarFormulario />} />
      <Route path={urlsBilingue.indeferirId} element={<BilingueIndeferir />} />
    </>
    )}
  </React.Fragment>
);

export default BilingueRoutes;
