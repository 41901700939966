import api from "..";

async function getDisciplinas() {
  return await api
    .get('disciplina')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function deleteDisciplina(id) {
  return await api
    .delete(`disciplina/${id}`)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function createDisciplina(data) {
  return await api
    .post('disciplina', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function updateDisciplina({ id, data }) {
  return await api
    .put(`disciplina/${id}`, data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function getDisciplina(id) {
  return await api
    .get(`disciplina/${id}`)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
}

async function getDisciplinasByTurmaServidor(turmaId, servidorId) {
  if(typeof turmaId === 'string'){
    return await api
    .get(`DisciplinaQuadroHorario/getDisciplinaByQuadrohorario/${turmaId}/${servidorId}/${servidorId}`)
    .then(async (res) => {
      const { data } = res.data;
      console.log(data);
      return data;
    });
  }
  else if(turmaId[0] == undefined){
    return await api
    .get(`DisciplinaQuadroHorario/getDisciplinaByQuadrohorario/${turmaId}/${servidorId}/${servidorId}`)
    .then(async (res) => {
      const { data } = res.data;
      console.log(data);
      return data;
    });
  }
  else{
    return await api
    .get(`DisciplinaQuadroHorario/getDisciplinaByQuadrohorario/${turmaId[0].id}/${servidorId}/${servidorId}`)
    .then(async (res) => {
      const { data } = res.data;
      console.log(data);
      return data;
    });
  }
};

export {
  getDisciplinas,
  deleteDisciplina,
  createDisciplina,
  updateDisciplina,
  getDisciplina,
  getDisciplinasByTurmaServidor
}